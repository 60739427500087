<template>
  <b-row class="mt-5 match-height">
    <b-col cols="12" md="12" class="">
      <b-nav pills class="align-items-start">
        <b-nav-item v-for="(tab, index) in tabs" :key="index" :to="{ name: tab.route }" :active="$route.name === tab.route" class="">
          <template>
            <feather-icon
              :icon="tab.icon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold font-lg">{{ tab.title }} </span>
          </template>
        </b-nav-item>
      </b-nav>
    </b-col>

    <b-col cols="12" md="12">
      <router-view></router-view>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BTabs, BNav, BRow, BCol, BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BNav,
    BTabs,
    BCard,
    BNavItem,
    BCardBody,
    BCardHeader,
  },
  data() {
    return {
      options: {},
      tabs: [
        {
          title: 'Job Applications',
          route: 'job-applications',
          icon: 'BriefcaseIcon',
        },
        {
          title: 'Application Letter Requests',
          route: 'letter-requests',
          icon: 'FileTextIcon',
        },
        {
          title: 'Interview Requests',
          route: 'interview-requests',
          icon: 'CalendarIcon',
        },
        // {
        //   title: 'Education History',
        //   route: 'education-history',
        //   icon: 'BookIcon',
        // },
        // {
        //   title: 'Skills',
        //   route: 'skills',
        //   icon: 'StarIcon',
        // },
        // {
        //   title: 'Voluntary Services',
        //   route: 'voluntary-services',
        //   icon: 'SunriseIcon',
        // },
        // {
        //   title: 'Hobbies & Interests',
        //   route: 'hobbies',
        //   icon: 'ThumbsUpIcon',
        // },
        // {
        //   title: 'References',
        //   route: 'references',
        //   icon: 'UsersIcon',
        // },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
  .font-lg {
    font-size: 13px;
  }
</style>
